.image-slide {
    opacity: 1;
    transition: 'opacity 1s';
    z-index: 999;
}

.image-fadeOut {
    opacity: 0;
    transition: 'opacity 1s';

}

.image-top {
    position: absolute;
    top: 10%;
}